export const FilterDeletedAndInactive = (item: unknown, key?: string, value?: unknown) => {
  if (!item) return false;

  const active = !item['Inactive'] && !item['IsDeleted'];

  if (key !== undefined && key !== null) {
    if (typeof item === 'string' || typeof item === 'number') {
      return item == value && active;
    } else {
      return item[key] == value && active;
    }
  }

  return active;
};
