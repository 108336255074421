import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, transient } from 'aurelia-framework';
import { Subscription } from 'aurelia-event-aggregator';
import { Events } from './Events';

@transient()
@autoinject
export class PubSub {
  private subscriptions: Subscription[] = [];

  constructor(private aggregator: EventAggregator) {}

  publish(event: keyof Events, data: Events[typeof event]): void {
    this.aggregator.publish(event, data);
  }

  sub<K extends keyof Events>(event: K, cb: (data: Events[K]) => void): void {
    this.subscriptions.push(this.aggregator.subscribe(event as unknown as () => void, cb));
  }

  unsub(): void {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }
}
