export class AuthorizeError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class ForbiddenError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class ServerError extends Error {
  constructor(message?: string) {
    super(message);
  }
}
